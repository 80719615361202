export const getPrecioPorW = (potencias, precios, w) => {
    const kW = w / 1000;
    let i = 0

    let precio = fetchPrecio(potencias, precios, kW, i)

    return precio
}

const fetchPrecio = (potencias, precios, ud, i) => {

    console.log(ud)

    if (ud <= potencias[i] && i < potencias.length) {

        return precios[i]

    } else if (i === potencias.length) {
        return precios[i - 1]
        
    } else {
        i++
        return fetchPrecio(potencias, precios, ud, i)
    }



}