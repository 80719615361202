import React, {useEffect, useState} from 'react';
import {getPrecioPorW} from '../utils/getPriceByW';

const potencias  = [10,  15,     30,     45,     60,     75, 90, 101, 121];
const precios    = [1300, 1600,  1800,   2000,   2200,   2500, 2900, 3300, 3500];
const paneles    = [22,      33,     133,    222,    300];
const preciosGrua= [200,      290,     550,     850,     950];

const KitProteccionesCableado = ({ totalPower, kitProteccionesCableadoPrice, setKitProteccionesCableadoPrice}) => {

    useEffect(() => {

        const price = getPrecioPorW(potencias, precios, totalPower);
        setKitProteccionesCableadoPrice({...kitProteccionesCableadoPrice, importe: price, total: price});
    }, [totalPower]);

    const handleonChangeKitProteccionesCableado = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * kitProteccionesCableadoPrice.ud;
            setKitProteccionesCableadoPrice({...kitProteccionesCableadoPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * kitProteccionesCableadoPrice.importe;
            setKitProteccionesCableadoPrice({...kitProteccionesCableadoPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        setKitProteccionesCableadoPrice({...kitProteccionesCableadoPrice, importe: event.target.value});
    };
    
    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Kit de protecciones y cableado</label>
                        <input className='form-control' type="text" defaultValue={"Kit de protecciones y cableado"}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Unidad</label>
                        <input className='form-control' type="number" name="ud" value={kitProteccionesCableadoPrice.ud} onChange={handleonChangeKitProteccionesCableado}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="importe" value={kitProteccionesCableadoPrice.importe} onChange={handleonChangeKitProteccionesCableado}/>
                    </div>
                    <div className="col-2">
                        <p>Importe:</p>
                        <h4>{kitProteccionesCableadoPrice.total} €</h4>
                    </div>
                </div>
            </div>

        </>

    );
}

export default KitProteccionesCableado;