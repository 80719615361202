export const getPrecioPorPanel = (paneles, precios, panel) => {
    let i = 0

    console.log(panel)
    console.log(paneles)

    let precio = fetchPrecio(paneles, precios, panel, i)

    console.log(precio)

    return precio
}

const fetchPrecio = (paneles, precios, ud, i) => {

    if (ud <= paneles[i] && i < paneles.length) {

        return precios[i]

    }else if (i === paneles.length) {
        console.log('i === paneles.length')
        console.log(i)
        console.log(precios[i])
        return precios[i-1]

        
    } else {
        i++
        return fetchPrecio(paneles, precios, ud, i)
    }



}

export const currencyFormat = (num) => {

    return num.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' });
}