import React, { useEffect, useState } from 'react'
import inversors from '../Data/inversors.json'

const fases = [
    { id: "monofasico", name: 'monofásico' },
    { id: "trifasico", name: 'trifásico' },
];


const Inversores = ({
    inversorSelected, 
    setInversorSelected,
    stringPower,
}) => {

    const [faseSelected, setFaseSelected] = useState(fases[0].id);

    useEffect(() => {
        setInversorSelected({...inversors[0], ud: 0, total: inversors[0].importe * 0});
        changeInversor(stringPower);
    }, [stringPower]);

    const handleOnChangeFase = (e) => {
        const { name, value } = e.target;
        setFaseSelected(value);
        const inversorNew = inversors.find((inversor) => inversor.entrada_maxima_w >= stringPower && inversor.sistema === value);
        setInversorSelected({...inversorNew, ud: 1, total: inversorNew.importe * 1});
    }



    const changeInversor = (stringPower) => {

        const potenciaMaximaInversorUnitario = Math.max(...inversors.map((inversor) => inversor.w));

        if (stringPower <= potenciaMaximaInversorUnitario) {
            const inversorNew = inversors.find((inversor) => inversor.w >= stringPower);
            setInversorSelected({...inversorNew, ud: 1, total: inversorNew.importe * 1});
        } else {
            const unidades = Math.ceil(stringPower / potenciaMaximaInversorUnitario);
            console.log(stringPower);
            console.log(unidades);
            const stringPowerUnitario = stringPower / unidades;
            console.log(stringPowerUnitario);
            const inversorNew = inversors.find((inversor) => inversor.w >= stringPowerUnitario);
            setInversorSelected({...inversorNew, ud: unidades, total: inversorNew.importe * unidades});
        }
    }

        
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        const inversorNew = inversors.find((inversor) => inversor.id === parseInt(value));
        setInversorSelected({...inversorSelected, ...inversorNew, ud: 1, total: inversorNew.importe * 1});
    
    }

    const handleOnChangePrice = (e) => {
        const { name, value } = e.target;
        setInversorSelected({...inversorSelected, importe: value, total: value * 1});
    }

    const handleOnChangeUd = (e) => {
        const { name, value } = e.target;
        setInversorSelected({...inversorSelected, ud: value, total: inversorSelected.importe * value})

    }

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-2">
                        <label className='form-label'>Fase</label>
                        <select className='form-control' name="fase" id="fase" onChange={handleOnChangeFase} value={faseSelected}>
                            {fases.map((fase, _) => (
                                <option key={fase.id} value={fase.id}>
                                    {fase.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-4">
                        <label className='form-label'>Inversor</label>
                        <select className='form-control' name="inversor" id="inversor" onChange={handleOnChange} value={inversorSelected.id}>
                            {inversors.map((inversor, i) => (
                                <option key={inversor.id} value={inversor.id}>
                                    {inversor.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Número de inversores</label>
                        <input className='form-control' type="number" name="num_inversores" id="num_inversores" value={inversorSelected.ud}  onChange={handleOnChangeUd}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="precio_inversor" id="precio_inversor" value={inversorSelected.importe} onChange={handleOnChangePrice}  />
                    </div>
                    <div className="col-2">
                        <p>Importe:</p>
                        <h4> {inversorSelected.total} €</h4>
                    </div>
                </div>
            </div>
            <div className="form-row">
            </div>

        </>
    )
}

export default Inversores
