

const TasaObras = ({tasaObrasPrice, setTasaObrasPrice}) => {

    const handleOnChangeTasa = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * tasaObrasPrice.ud;
            setTasaObrasPrice({...tasaObrasPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * tasaObrasPrice.importe;
            setTasaObrasPrice({...tasaObrasPrice, [event.target.name]: event.target.value, total: total});
            return;
        }

        setTasaObrasPrice({...tasaObrasPrice, [event.target.name]: event.target.value})
    };

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Tasa Obras</label>
                        <input className='form-control' type="text" name="name" value={tasaObrasPrice.name} onChange={handleOnChangeTasa}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Unidad</label>
                        <input className='form-control' type="number" name="ud" value={tasaObrasPrice.ud} onChange={handleOnChangeTasa}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="importe" value={tasaObrasPrice.importe} onChange={handleOnChangeTasa}/>
                    </div>
                    <div className="col-2">
                        <p>Importe: </p>
                        <h4>{tasaObrasPrice.importe} €</h4>
                    </div>
                </div>
            </div>

        </>

    );
}

export default TasaObras;