import React, { useEffect, useState } from 'react'
import { getPrecioPorPanel } from '../utils/getPriceByPanel';
import estructuras from '../Data/estructura.json'
import precios from '../Data/estructura_precios.json'

    const paneles           = [22,      33,     133,    222,    300];


const Estructura = ({
    udPaneles,
    estructuraSelected,
    setEstructuraSelected
}) => {

    useEffect(() => {
        const estructura = estructuras.find((estructura) => estructura.id === parseInt(estructuraSelected.id));
        const precio = precios.find((precio) => precio.tipoCubierta === estructura.tipoCubierta);
        const precioPorPaneles = getPrecioPorPanel(paneles, precio.precioPorPanel, udPaneles);
        console.log("precio por paneles");
        console.log(precioPorPaneles);
        setEstructuraSelected({...estructura, ud: 1, importe: udPaneles * precioPorPaneles, total: udPaneles * precioPorPaneles});
    }, [udPaneles]);

    const handleOnChangeEstructura = (e) => {
        const { name, value } = e.target;
        const estructura = estructuras.find((estructura) => estructura.id === parseInt(value));
        const precio = precios.find((precio) => precio.tipoCubierta === estructura.tipoCubierta);
        const precioPorPaneles = getPrecioPorPanel(paneles, precio.precioPorPanel, udPaneles);
        setEstructuraSelected({...estructura, ud: 1, importe: udPaneles * precioPorPaneles, total: udPaneles * precioPorPaneles});
    }

    
    const handleOnChangePrice = (e) => {
        const { name, value } = e.target;
        setEstructuraSelected({...estructuraSelected, importe: value, total: value * estructuraSelected.ud});
    }

    const handleOnChangeUd = (e) => {
        const { name, value } = e.target;
        setEstructuraSelected({...estructuraSelected, ud: value, total: value * estructuraSelected.importe});
    }

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Tipo Estructura</label>
                        <select className='form-control' name="tipo_estructura" id="tipo_estructura" onChange={handleOnChangeEstructura}>
                            {estructuras.map((estructura, i) => (
                                <option key={estructura.id} value={estructura.id} selected={estructura.id === estructuraSelected.id ? true : false} >
                                    {estructura.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                            <label className='form-label'>Ud</label>
                            <input className='form-control' type="number" name="num_estructura" id="num_estructura" value={estructuraSelected.ud}  onChange={handleOnChangeUd}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="precio_estructura" id="precio_estructura" value={estructuraSelected.importe} onChange={handleOnChangePrice}  />
                    </div>
                    <div className="col-2">
                        <p>Importe:</p>
                        <h4>{estructuraSelected.total} €</h4>

                    </div>

                </div>
            </div>

        </>

    );

};

export default Estructura;

                    