import React, {useEffect, useState} from 'react';
import {getPrecioPorW} from '../utils/getPriceByW';
const potencias  = [10,     15,     30,   60,     101,    121]; //kw
const precios    = [0.57,   0.46,   0.412, 0.38,   0.35,   0.23]; //w


const Instalacion = ({totalPower, instalacionPrice, setInstalacionPrice}) => {
    useEffect(() => {
        const price = getPrecioPorW(potencias, precios, totalPower);
        setInstalacionPrice({...instalacionPrice, importe: totalPower * price, total: totalPower * price});
    }, [totalPower]);

    const handleOnChangeInstalacion = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * instalacionPrice.ud;
            setInstalacionPrice({...instalacionPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * instalacionPrice.importe;
            setInstalacionPrice({...instalacionPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        setInstalacionPrice({...instalacionPrice, importe: event.target.value});
    };

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Instalación</label>
                        <input className='form-control' type="text" defaultValue={instalacionPrice.name} onChange={handleOnChangeInstalacion}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Unidad</label>
                        <input className='form-control' type="number" name="ud" value={instalacionPrice.ud} onChange={handleOnChangeInstalacion}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="importe" value={instalacionPrice.importe} onChange={handleOnChangeInstalacion}/>
                    </div>
                    <div className="col-2">
                        <p>Importe:</p>
                        <h4>{instalacionPrice.total} €</h4>
                    </div>
                </div>
            </div>
            <div className="form-row">
            </div>

        </>

    );
}

export default Instalacion;