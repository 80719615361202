import React, {useState, useEffect} from 'react'
import { Box } from '@mui/material';
import aislamiento from '../Data/aislamient.json';
import zonaClimate from '../Data/zonaclimat.json';
import orientation from '../Data/orientatio.json';

const AerotermiaCalculadorPotencia = () => {

    const [superficie, setSuperficie] = useState(0);
    const [zonaClimateSelected, setZonaClimateSelected] = useState(zonaClimate[0]);
    const [aislamientoSelected, setAislamientoSelected] = useState(aislamiento[0]);
    const [orientationSelected, setOrientationSelected] = useState(orientation[0]);
    const [potencia, setPotencia] = useState(0);

    useEffect(() => {
        potenciaInstalar();
    }, [superficie, zonaClimateSelected, aislamientoSelected, orientationSelected]);

    const handleOnChangeSuperficie = (e) => {
        const value = e.target.value;
        setSuperficie(value);
    }

    const handleOnChangeZonaClimate = (e) => {
        const value = e.target.value;
        console.log(value);
        const item = zonaClimate.find((item) => item.id === value);
        console.log(item);
        setZonaClimateSelected(item);
    }

    const handleOnChangeAislamiento = (e) => {
        const value = e.target.value;
        const item = aislamiento.find((item) => item.id === value);
        console.log(item);
        setAislamientoSelected(item);
    }

    const handleOnChangeOrientation = (e) => {
        const value = e.target.value;
        const item = orientation.find((item) => item.id === value);
        console.log(item);
        setOrientationSelected(item);
    }

    const potenciaInstalar = () => {
        const potencia = superficie * zonaClimateSelected.coeficient * aislamientoSelected.coeficient * orientationSelected.coeficient * 85 / 1000;
        setPotencia(potencia);
    }

    return (
        <Box marginY={10}>
            <h2>Calculadora Aerotermia</h2>

            <div className="row">
                <div className="col-3">
                    <label className='form-label'>Superficie (m2)</label>

                    <input type="number" className="form-control" placeholder="Superficie (m2)" value={superficie} onChange={handleOnChangeSuperficie} />
                </div>

                <div className="col-3">
                    <label className='form-label'>Zona Climatica</label>
                    <select className="form-select" aria-label="Default select example" id="zonaClimatica" onChange={handleOnChangeZonaClimate}>
                        {zonaClimate.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                            
                            ))}
                    </select>
                </div>

                <div className="col-3">
                    <label className='form-label'>aislamiento</label>
                    <select className="form-select" aria-label="Default select example" id="aislamiento" onChange={handleOnChangeAislamiento}>
                        {aislamiento.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                        ))}
                    </select>
                </div>

                <div className="col-3">
                    <label className='form-label'>Orientación</label>
                    <select className="form-select" aria-label="Default select example" id="orientacion" onChange={handleOnChangeOrientation}>
                        {orientation.map((item) => (
                            <option key={item.id} value={item.id}>{item.name}</option>
                            ))}
                    </select>
                </div>
            </div>
            <h4>Potencia a instalar</h4>
            <p>{potencia.toFixed(2)} kW</p>



        </Box>
    );

}

export default AerotermiaCalculadorPotencia;