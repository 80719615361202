import React, { useEffect } from 'react'


const ChildItem = ({customItem, setCustomItems, childName}) => {


    const handleOnChangePrice = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * customItem.ud;
            
            const modifiedCustomItems = {...customItem, [event.target.name]: event.target.value, total: total};

            setCustomItems(modifiedCustomItems);
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * customItem.importe;
            
            const modifiedCustomItems = {...customItem, [event.target.name]: event.target.value, total: total};

            setCustomItems(modifiedCustomItems);
            return;
        }

        const modifiedCustomItems = {...customItem, [event.target.name]: event.target.value}
        setCustomItems(modifiedCustomItems);

}

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>{customItem.name}</label>
                        <input className='form-control' type="text" name="name" onChange={handleOnChangePrice} value={customItem.name}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Unidad</label>
                        <input className='form-control' type="number" onChange={handleOnChangePrice} name='ud' value={customItem.ud}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" onChange={handleOnChangePrice} name="importe" value={customItem.importe} />
                    </div>
                    <div className="col-2">
                        <p>Importe: </p>
                        <h4>{customItem.total} €</h4>
                    </div>
                </div>
            </div>
        </>

    )
}

export default ChildItem