import React, { useEffect } from 'react'
import aerotermias from '../Data/aerotermia.json'

const Aerotermias = ({
    aerotermiaSelected, 
    setAerotermiaSelected,
}) => {


    useEffect(() => {
        setAerotermiaSelected({...aerotermias[0], ud: 1, total: aerotermias[0].importe * 1});
    }, []);

        
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        const inversorNew = aerotermias.find((inversor) => inversor.id === parseInt(value));
        setAerotermiaSelected({...aerotermiaSelected, ...inversorNew, ud: 1, total: inversorNew.importe * 1});
    
    }

    const handleOnChangePrice = (e) => {
        const { name, value } = e.target;
        setAerotermiaSelected({...aerotermiaSelected, importe: value, total: value * 1});
    }

    const handleOnChangeUd = (e) => {
        const { name, value } = e.target;
        setAerotermiaSelected({...aerotermiaSelected, ud: value, total: aerotermiaSelected.importe * value})

    }

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Aerotermia</label>
                        <select className='form-control' name="aerotermia" id="aerotermia" onChange={handleOnChange} value={aerotermiaSelected.id}>
                            {aerotermias.map((aerotermia, i) => (
                                <option key={aerotermia.id} value={aerotermia.id}>
                                    {aerotermia.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Número</label>
                        <input className='form-control' type="number" name="num_aerotermia" id="num_aerotermia" value={aerotermiaSelected.ud}  onChange={handleOnChangeUd}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="precio_inversor" id="precio_inversor" value={aerotermiaSelected.importe} onChange={handleOnChangePrice}  />
                    </div>
                    <div className="col-2">
                        <p>Importe:</p>
                        <h4> {aerotermiaSelected.total} €</h4>
                    </div>
                </div>
            </div>
            <div className="form-row">
            </div>

        </>
    )
}

export default Aerotermias
