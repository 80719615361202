import React, { useState } from 'react';
import { Box } from '@mui/material';
import PdfViewer from './PdfViewer';
import { TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {currencyFormat} from '../utils/getPriceByPanel';

const Drawer = ({strings, presupuesto, totalSinIva}) => {


    const [tipoIva, setTipoIva] = useState(10)
    const [cliente, setCliente] = useState('')
    const [direccion, setDireccion] = useState('')

    const handleOnChangeCliente = (event) => {
        setCliente(event.target.value)
    }

    const handleOnChangeDireccion = (event) => {
        setDireccion(event.target.value)
    }

    const handleOnChangeTipoIva = (event) => {
        setTipoIva(event.target.value)
    }
      
    return (


        <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{width: "1050px"}}>
        <div className="offcanvas-header">
            <h5 id="offcanvasRightLabel">Presupuesto</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">

        <Box marginY={5}>
            <h5>Cliente</h5>
            <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
            >
            <TextField id="cliente" label="Razón social" variant="standard" onChange={handleOnChangeCliente} />
            <TextField id="direccion" label="Dirección" variant="standard" onChange={handleOnChangeDireccion} />

            <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Tipo IVA</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={tipoIva}
                label="Iva"
                onChange={handleOnChangeTipoIva}
            >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={21}>21</MenuItem>
            </Select>
            </FormControl>
            </Box>
        </Box>

        <Box>
        <div style={{ color: 'black', lineHeight : 2, padding: 10}}>
        <table>
        <tr>
    <th>nombre&#160;&#160;</th>
    <th>unidades&#160;&#160;</th>
    <th>importe&#160;&#160;</th>
    <th>total</th>
  </tr>
            {strings.map((string, i) => (
                <>
                {string.lines.map((line, j) => (
                    <>
                    <tr>
                    <td>{line.name}&#160;&#160;&#160;&#160;</td>
                    <td>{line.ud}</td>
                    <td>{line.importe}</td>
                    <td>{line.total}</td> 
                    </tr>
                    </>
                ))}

                {presupuesto.map((line, i) => (
                    <>
                    <tr>
                    <td>{line.name}&#160;&#160;&#160;&#160;</td>
                    <td>{line.ud}</td>
                    <td>{line.importe}</td>
                    <td>{line.total}</td>
                    </tr>
                    </>
                ))}
                
                </>
            ))}
        &#160;
        <tr>
        <td><b>Subtotal</b></td>
        <td>{currencyFormat(totalSinIva)}</td>
        </tr>
        <tr>
        <td><b>10% IVA</b></td>
        <td>{currencyFormat(totalSinIva * (tipoIva / 100))}</td>
        </tr>
        <tr>
        <td><strong>Total</strong></td>
        <td>{currencyFormat((totalSinIva * (1 + tipoIva / 100)))}</td>
        </tr>

        </table>
        </div>
        </Box>


        <Box marginY={5}>
            <PdfViewer strings={strings} presupuesto={presupuesto} totalSinIva={totalSinIva} cliente={cliente} direccion={direccion} tipoIva={tipoIva} />
        </Box>
        <Box marginY={5}>
            Datos json de los strings
            <textarea type="text" rows="10" className="form-control" value={JSON.stringify(strings)} />
        </Box>
        <Box marginY={5}>
            Datos json de otros conceptos
            <textarea type="text" rows="10" className="form-control" value={JSON.stringify(presupuesto)} />
        </Box>
        </div>
    </div>
    )
}

export default Drawer