import React, { useEffect } from 'react'
import acumuladores from '../Data/acumulador.json'

const Acumuladores = ({
    acumuladorSelected, 
    setAcumuladorSelected,
}) => {


    useEffect(() => {
        setAcumuladorSelected({...acumuladores[0], ud: 1, total: acumuladores[0].importe * 1});
    }, []);

        
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        const inversorNew = acumuladores.find((inversor) => inversor.id === parseInt(value));
        setAcumuladorSelected({...acumuladorSelected, ...inversorNew, ud: 1, total: inversorNew.importe * 1});
    
    }

    const handleOnChangePrice = (e) => {
        const { name, value } = e.target;
        setAcumuladorSelected({...acumuladorSelected, importe: value, total: value * 1});
    }

    const handleOnChangeUd = (e) => {
        const { name, value } = e.target;
        setAcumuladorSelected({...acumuladorSelected, ud: value, total: acumuladorSelected.importe * value})

    }

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Acumulador</label>
                        <select className='form-control' name="acumulador" id="acumulador" onChange={handleOnChange} value={acumuladorSelected.id}>
                            {acumuladores.map((acumulador, i) => (
                                <option key={acumulador.id} value={acumulador.id}>
                                    {acumulador.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Número</label>
                        <input className='form-control' type="number" name="num_acumulador" id="num_acumulador" value={acumuladorSelected.ud}  onChange={handleOnChangeUd}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="precio_acumulador" id="precio_acumulador" value={acumuladorSelected.importe} onChange={handleOnChangePrice}  />
                    </div>
                    <div className="col-2">
                        <p>Importe:</p>
                        <h4> {acumuladorSelected.total} €</h4>
                    </div>
                </div>
            </div>
            <div className="form-row">
            </div>

        </>
    )
}

export default Acumuladores
