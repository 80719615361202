import React, { useEffect } from 'react'
import batteries from '../Data/battery2.json'

const Battery = ({batteryPrice, setBatteryPrice}) => {
    useEffect(() => {
        setBatteryPrice({...batteries[0], ud: 1, importe: batteries[0].price, total: batteries[0].price});
    }, []);

    const handleBatterySelect = (event) => {
        const meter = batteries.find(meter => meter.id === parseInt(event.target.value));
        setBatteryPrice({...meter, ud: 1, importe: meter.price, total: meter.price});
    }

    const handleBattery = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * batteryPrice.ud;
            setBatteryPrice({...batteryPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * batteryPrice.importe;
            setBatteryPrice({...batteryPrice, [event.target.name]: event.target.value, total: total});
            return;
        }

        setBatteryPrice({...batteryPrice, [event.target.name]: event.target.value})
    }


    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Batería</label>
                        <select name="meter" className='form-control' id="meter" onChange={handleBatterySelect}>
                            {batteries.map((meter, i) => (
                                <option key={meter.id} value={meter.id}>
                                    {meter.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <label className="form-label">Ud</label>
                        <input type="number" className='form-control' name="ud" value={batteryPrice.ud} onChange={handleBattery}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe:</label>
                        <input className='form-control' type="number" name="importe" value={batteryPrice.importe} onChange={handleBattery}/>
                    </div>
                    <div className="col-2">
                        <p>Importe: </p>
                        <h4>{batteryPrice.total} €</h4>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Battery