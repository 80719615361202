import React, { useEffect, useState } from "react";
import paneles from "../Data/paneles.json"


const Panels = ({
    panelSelected, 
    setPanelSelected,
    stringPower,
    setStringPower,
}) => {

    useEffect(() => {
        setPanelSelected({...paneles[0], ud: 0, total: paneles[0].importe * 0})
    }, []);


    const handleOnChangeUd = (e) => {
        const { name, value } = e.target;
        setStringPower(value * panelSelected.w);
        setPanelSelected({ ...panelSelected, ud: value, total: value * panelSelected.importe });
    }

    const handleOnChangePanel = (e) => {
        const { name, value } = e.target;
        const panel = paneles.find((panel) => panel.id === parseInt(value));
        setStringPower(panelSelected.ud * panel.w);
        panel.ud = panelSelected.ud;
        panel.total = panel.importe * panelSelected.ud;
        setPanelSelected(panel);
    }

    const handleOnChangePrice = (e) => {
        const { name, value } = e.target;
        setPanelSelected({...panelSelected, importe: value, total: value * panelSelected.ud});
    }


    return (
        <>
            <div className="row">
                <div className="col-6">
                    <label className="form-label">Panel</label>
                    <select className="form-control" name="string" id="string" onChange={handleOnChangePanel} defaultValue={panelSelected.id} >
                        {paneles.map((panel, i) => (
                            <option key={panel.id} value={panel.id} >
                                {panel.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-2">
                    <label className="form-label">Número de paneles</label>
                    <input className="form-control" type="number" name="num_paneles" id="num_paneles" min="0" onChange={handleOnChangeUd} value={panelSelected.ud} />
                </div>
                <div className="col-2">
                    <label className="form-label">Importe</label>
                    <input className="form-control" type="number" name="precio_panel" id="precio_panel" value={panelSelected.importe} onChange={handleOnChangePrice} />
                </div>
                <div className="col-2">
                    <p>Importe:</p>
                    <h4> {panelSelected.total} €</h4>
                </div>
            </div>
        
        </>
    )
}

export default Panels