import React, { useEffect, useState } from "react";
import kits from "../Data/kit.json";
import inversors from "../Data/inversors.json"
import meters from "../Data/meters.json"
import batteries from "../Data/battery.json"
import marcaplaca from "../Data/marcaplaca.json"

const KitCompleto = ({
    kitSelected, 
    setKitSelected,
    stringPower,
    setStringPower,
}) => {

    const [marcaplacaSelected, setMarcaPlacaSelected] = useState(marcaplaca[0].id);
    const [placasNecesarias, setPlacasNecesarias] = useState(0);
    const [filteredKits, setFilteredKits] = useState([]);

    const [inverChecked, setInverChecked] = useState(false);
    const [huaweiChecked, setHuaweiChecked] = useState(false);
    const [monofasicoChecked, setMonofasicoChecked] = useState(false);
    const [trifasicoChecked, setTrifasicoChecked] = useState(false);

    const [kwhconsumo, setkwhConsumo] = useState(0);
    const [solhoras, setsolHoras] = useState(0);

    useEffect(() => {
        setKitSelected({...kits[0], ud: 1, total: kits[0].importe * 1})
    }, []);

    const handleOnChangeMarcaPlaca = (e) => {
        const { value } = e.target;
        setMarcaPlacaSelected(value);
    }
    
    useEffect(() => {
        const selectedMarcaPlaca = marcaplaca.find(item => item.id === parseInt(marcaplacaSelected));
        let updatedFilteredKits = marcaplacaSelected ? kits.filter(item => item.brand.includes(selectedMarcaPlaca?.brand)) : kits;
        updatedFilteredKits = updatedFilteredKits.filter(item => {
            // Check both conditions
            const meetsInverCondition = (!inverChecked || item.inver === "Solplanet") && (!huaweiChecked || item.inver === "Huawei");
            const meetsFaseCondition = (!monofasicoChecked || item.fase === "mono") && (!trifasicoChecked || item.fase === "tri");
    
            // Return true only if both conditions are met
            return meetsInverCondition && meetsFaseCondition;
        });
        setFilteredKits(updatedFilteredKits);
    }, [marcaplacaSelected, inverChecked, huaweiChecked, monofasicoChecked, trifasicoChecked]);

    // Handler for checkbox changes
    const handleCheckboxChange = () => {
        handleOnChangeMarcaPlaca(); // Trigger filtering function
    }
    

    const handleOnChangeUd = (e) => {
        const { name, value } = e.target;
        setStringPower(value * kitSelected.w);
        setKitSelected({ ...kitSelected, ud: value, total: value * kitSelected.importe });
    }

    const handleOnChangeKit = (e) => {
        const { name, value } = e.target;
        const kit = kits.find((kit) => kit.id === parseInt(value));
        let updatedImporte = kit.importe;
    
        // Check if the selected solar panel brand exists in the kits
        const matchingMarcaPlaca = marcaplaca.find(item => kit.brand.includes(item.brand));
    
        // If a matching brand is found, update the importe
        if (matchingMarcaPlaca) {
            updatedImporte += matchingMarcaPlaca.importe * kit.panelud; // Multiply by panelud
        }
    
        // Check if the selected inversor id exists in the inversors
        const matchingInversor = inversors.find(item => item.id === kit.inversorid);
    
        // If a matching inversor id is found, add its importe to the total
        if (matchingInversor) {
            updatedImporte += matchingInversor.importe;
        }
    
        // Check if the selected meter id exists in the meters
        const matchingMeter = meters.find(item => item.id === kit.meterid);
    
        // If a matching meter id is found, add its importe to the total
        if (matchingMeter) {
            updatedImporte += matchingMeter.importe;
        }
    
        // Check if the selected battery id exists in the batteries
        const matchingBattery = batteries.find(item => item.id === kit.batteryid);
    
        // If a matching battery id is found, add its importe to the total
        if (matchingBattery) {
            updatedImporte += matchingBattery.importe;
        }
    
        // Calculate the price of the structures based on the number of panels
        const structuresPrice = kit.panelud * 50;
    
        // Add the structures price to the total importe
        updatedImporte += structuresPrice;
    
        // Calculate the price of ingenieria based on kit.w
        let ingenieriaPrice = 0;
        if (kit.w <= 10000) {
            ingenieriaPrice = 1200;
        } else if (kit.w <= 15000) {
            ingenieriaPrice = 1800;
        } else if (kit.w <= 60000) {
            ingenieriaPrice = 2500;
        } else if (kit.w <= 101000) {
            ingenieriaPrice = 3000;
        } else {
            ingenieriaPrice = 3500;
        }
    
        // Add the ingenieria price to the total importe
        updatedImporte += ingenieriaPrice;
    
        // Calculate the price of instalacion based on kit.w
        let instalacionPrice = kit.w * 0.57; // Default coefficient for instalacion
        if (kit.w <= 10000) {
            instalacionPrice = kit.w * 0.57;
        } else if (kit.w <= 15000) {
            instalacionPrice = kit.w * 0.46;
        } else if (kit.w <= 60000) {
            instalacionPrice = kit.w * 0.412;
        } else if (kit.w <= 101000) {
            instalacionPrice = kit.w * 0.38;
        } else {
            instalacionPrice = kit.w * 0.35;
        }
    
        // Add the instalacion price to the total importe
        updatedImporte += instalacionPrice;
    
        // Calculate the price of kitproteccionescable based on kit.w
        let proteccionescablePrice = 0;
        if (kit.w <= 10000) {
            proteccionescablePrice = 1300;
        } else if (kit.w <= 15000) {
            proteccionescablePrice = 1600;
        } else if (kit.w <= 30000) {
            proteccionescablePrice = 1800;
        } else if (kit.w <= 45000) {
            proteccionescablePrice = 2000;
        } else if (kit.w <= 60000) {
            proteccionescablePrice = 2200;
        } else if (kit.w <= 75000) {
            proteccionescablePrice = 2500;
        } else if (kit.w <= 90000) {
            proteccionescablePrice = 2900;
        } else if (kit.w <= 101000) {
            proteccionescablePrice = 3300;
        } else {
            proteccionescablePrice = 3500;
        }
    
        // Add the kitproteccionescable price to the total importe
        updatedImporte += proteccionescablePrice;
    
        setStringPower(kitSelected.ud * kit.w);
        kit.ud = kitSelected.ud;
        kit.total = updatedImporte * kitSelected.ud;
        setKitSelected(kit);
    }
    

    const handleOnChangePrice = (e) => {
        const { name, value } = e.target;
        setKitSelected({...kitSelected, importe: value, total: value * kitSelected.ud});
    }

    const placasNecesariasCalc = () => {
        const selectedId = parseInt(marcaplacaSelected);
        const marca = marcaplaca.find(marca => marca.id === selectedId);
    
        if (marca) {
            const kwPlacas = parseFloat(marca.kw);
            console.log("kwhconsumo:", kwhconsumo);
            console.log("kwPlacas:", kwPlacas);
            const placasNecesarias = Math.ceil((kwhconsumo/12)/(kwPlacas*30*solhoras));
            console.log("placasNecesarias:", placasNecesarias);
            setPlacasNecesarias(placasNecesarias);
        } else {
            // Handle case where selected ID doesn't match any items in the marcaplaca array
            console.error("Selected marca ID not found in marcaplaca array");
        }
    };

    const handleOnChangekwhconsumo = (e) => {
        const { name, value } = e.target;
        setkwhConsumo(value);
    }

    const handleOnChangesolHoras = (e) => {
        const { name, value } = e.target;
        setsolHoras(value);
    }


    useEffect (()=> {
        placasNecesariasCalc();
    }, [kwhconsumo, solhoras, marcaplacaSelected])



    return (
        <>
            <div className="form-card">
                <div className="row" style={{marginBottom: "15px"}}>
                    <div className="col-2">
                        <label className='form-label'>KWH AÑO</label>
                        <input className='form-control' type="number" name="name" onChange={handleOnChangekwhconsumo} value={kwhconsumo}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>HORAS SOL DÍA</label>
                        <input className='form-control' type="number" name="name"onChange={handleOnChangesolHoras} value={solhoras}/>
                    </div>
                    <div className="col-3">
                        <label className='form-label'>Placa</label>
                        <select className='form-control' name="marcaplaca" id="marcaplaca" onChange={handleOnChangeMarcaPlaca} value={marcaplacaSelected}>
                            {marcaplaca.map((marcaplaca, _) => (
                                <option key={marcaplaca.id} value={marcaplaca.id}>
                                    {marcaplaca.name}
                                </option>
                            ))} 
                        </select>
                    </div>
                    <div className="col-3">
                        <p>Número Placas Necesarias:</p>
                        <h4> {placasNecesarias}</h4>
                    </div>
                    </div>
                    <div className="row" style={{marginBottom: "15px"}}>
                    <div className="col-3">
                    <input 
                        type="checkbox" 
                        checked={inverChecked} 
                        onChange={() => {
                        setInverChecked(!inverChecked);
                        // Trigger filtering function directly
                        handleOnChangeMarcaPlaca({ target: { value: marcaplacaSelected } });
                        }} 
                    />
                    <label htmlFor="inver">&nbsp;Inversor Solplanet</label>
                    <br></br>
                    <input 
                        type="checkbox" 
                        checked={huaweiChecked} 
                        onChange={() => {
                        setHuaweiChecked(!huaweiChecked);
                        // Trigger filtering function directly
                        handleOnChangeMarcaPlaca({ target: { value: marcaplacaSelected } });
                        }} 
                    />
                    <label htmlFor="huawei">&nbsp;Inversor Huawei</label>
                    </div>
                    <div className="col-3">
                    <input 
                        type="checkbox" 
                        checked={monofasicoChecked} 
                        onChange={() => {
                        setMonofasicoChecked(!monofasicoChecked);
                        // Trigger filtering function directly
                        handleOnChangeMarcaPlaca({ target: { value: marcaplacaSelected } });
                        }} 
                    />
                    <label htmlFor="mono">&nbsp;Monofásico</label>
                    <br></br>
                    <input 
                        type="checkbox" 
                        checked={trifasicoChecked} 
                        onChange={() => {
                        setTrifasicoChecked(!trifasicoChecked);
                        // Trigger filtering function directly
                        handleOnChangeMarcaPlaca({ target: { value: marcaplacaSelected } });
                        }} 
                    />
                    <label htmlFor="tri">&nbsp;Trifásico</label>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-6">
                    <label className="form-label">Equipo</label>
                    <select className="form-control" code="string" name="string" id="string" onChange={handleOnChangeKit} defaultValue={kitSelected.id} >
                        {filteredKits.map((kit, i) => (
                            <option key={kit.id} value={kit.id} >
                                {kit.code}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-2">
                    <label className="form-label">Unidades</label>
                    <input className="form-control" type="number" name="num_kits" id="num_kits" min="0" onChange={handleOnChangeUd} value={kitSelected.ud} />
                </div>
                <div className="col-2">
                    <label className="form-label">Importe</label>
                    <input className="form-control" type="number" name="precio_kit" id="precio_kit" value={kitSelected && kitSelected.total ? kitSelected.total.toFixed(2) : ''} onChange={handleOnChangePrice} />
                </div>
                <div className="col-2">
                    <p>Importe:</p>
                    <h4> {kitSelected && kitSelected.total ? kitSelected.total.toFixed(2) : ''} €</h4>
                </div>
            </div>
        
        </>
    )
}

export default KitCompleto