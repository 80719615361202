import React, { useEffect, useState } from 'react'
import dongles from '../Data/dongle.json'

const Dongle = ({donglePrice, setDonglePrice}) => {
    useEffect(() => {
        setDonglePrice({...dongles[0], ud: 0, importe: dongles[0].price, total: dongles[0].price});
    }, []);

    const handleDongleSelect = (event) => {
        const dongle = dongles.find(dongle => dongle.id === parseInt(event.target.value));
        setDonglePrice({...dongle, ud: 1, importe: dongle.price, total: dongle.price});
    }

    const handleDongle = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * donglePrice.ud;
            setDonglePrice({...donglePrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * donglePrice.importe;
            setDonglePrice({...donglePrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        setDonglePrice({ ...donglePrice, importe: event.target.value });
    };

    return (
        <>
            <div className='form-card'>
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Dongle</label>
                        <select className='form-control' name="Dongle" id="Dongle" onChange={handleDongleSelect}>
                            {dongles.map((dongle, i) => (
                                <option key={dongle.id} value={dongle.id}>
                                    {dongle.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <label className="form-label">Número de dongles</label>
                        <input className='form-control' type="number" name="ud" value={donglePrice.ud} onChange={handleDongle}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe:</label>
                        <input className='form-control' type="number" name="importe" value={donglePrice.importe} onChange={handleDongle}/>
                    </div>
                    <div className="col-2">
                        <p>Importe: </p>
                        <h4>{donglePrice.total} €</h4>
                    </div>
                
                
                 </div>
            </div>
        </>

    )
}

export default Dongle