import React, { useEffect } from 'react'
import cargadores from '../Data/cargador.json'

const Cargadores = ({
    cargadorSelected,
    setCargadorSelected
}) => {

    useEffect(() => {
        setCargadorSelected({...cargadores[0], ud: 1, total: cargadores[0].importe * 1});
    }, []);

        
    const handleOnChange = (e) => {
        const { name, value } = e.target;
        const inversorNew = cargadores.find((inversor) => inversor.id === parseInt(value));
        setCargadorSelected({...cargadorSelected, ...inversorNew, ud: 1, total: inversorNew.importe * 1});
    
    }

    const handleOnChangePrice = (e) => {
        const { name, value } = e.target;
        setCargadorSelected({...cargadorSelected, importe: value, total: value * 1});
    }

    const handleOnChangeUd = (e) => {
        const { name, value } = e.target;
        setCargadorSelected({...cargadorSelected, ud: value, total: cargadorSelected.importe * value})

    }




    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Cargador</label>
                        <select className='form-control' name="cargadores" id="cargadores" onChange={handleOnChange} value={cargadorSelected.id}>
                            {cargadores.map((cargador, i) => (
                                <option key={cargador.id} value={cargador.id}>
                                    {cargador.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Número</label>
                        <input className='form-control' type="number" name="num_cargador" id="num_cargador" value={cargadorSelected.ud}  onChange={handleOnChangeUd}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" name="precio_inversor" id="precio_inversor" value={cargadorSelected.importe} onChange={handleOnChangePrice}  />
                    </div>
                    <div className="col-2">
                        <p>Importe:</p>
                        <h4> {cargadorSelected.total} €</h4>
                    </div>
                </div>
            </div>
            <div className="form-row">
            </div>

        </>
    )
}

export default Cargadores