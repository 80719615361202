import React, { useState, useEffect } from 'react';
import { getPrecioPorW } from '../utils/getPriceByW';

const potencias  = [10, 15, 60, 101, 121];
const precios    = [1200, 1800, 2500, 3000, 3500];


const Ingenieria = ({totalPower, ingenieriaPrice, setIngenieriaPrice}) => {

    useEffect(() => {
        const price = getPrecioPorW(potencias, precios, totalPower);
        setIngenieriaPrice({...ingenieriaPrice, importe: price, total: price});
        console.log(price);
    }, [totalPower]);
    

    const handleOnChangePrice = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * ingenieriaPrice.ud;
            setIngenieriaPrice({...ingenieriaPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * ingenieriaPrice.importe;
            setIngenieriaPrice({...ingenieriaPrice, [event.target.name]: event.target.value, total: total});
            return;
        }

        setIngenieriaPrice({...ingenieriaPrice, [event.target.name]: event.target.value})
    }

    
    

    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Ingeniería</label>
                        <input className='form-control' type="text" name="name" onChange={handleOnChangePrice} value={ingenieriaPrice.name}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Unidad</label>
                        <input className='form-control' type="number" onChange={handleOnChangePrice} name='ud' value={ingenieriaPrice.ud}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe</label>
                        <input className='form-control' type="number" onChange={handleOnChangePrice} name="importe" value={ingenieriaPrice.importe} />
                    </div>
                    <div className="col-2">
                        <p>Importe: </p>
                        <h4>{ingenieriaPrice.total} €</h4>
                    </div>
                </div>
            </div>

        </>

    );
}

export default Ingenieria;