import React, { useEffect } from 'react'
import meters from "../Data/meter.json"

const Meter = ({meterPrice, setMeterPrice}) => {
    useEffect(() => {
        setMeterPrice({...meters[0], ud: 1, importe: meters[0].price, total: meters[0].price});
    }, []);

    const handleMeterSelect = (event) => {
        const meter = meters.find(meter => meter.id === parseInt(event.target.value));
        setMeterPrice({...meter, ud: 1, importe: meter.price, total: meter.price});
    }

    const handleMeter = (event) => {
        if (event.target.name === 'importe') {
            const total = event.target.value * meterPrice.ud;
            setMeterPrice({...meterPrice, [event.target.name]: event.target.value, total: total});
            return;
        }
        if (event.target.name === 'ud') {
            const total = event.target.value * meterPrice.importe;
            setMeterPrice({...meterPrice, [event.target.name]: event.target.value, total: total});
            return;
        }

        setMeterPrice({...meterPrice, [event.target.name]: event.target.value})
    }


    return (
        <>
            <div className="form-card">
                <div className="row">
                    <div className="col-6">
                        <label className='form-label'>Contador</label>
                        <select name="meter" className='form-control' id="meter" onChange={handleMeterSelect}>
                            {meters.map((meter, i) => (
                                <option key={meter.id} value={meter.id}>
                                    {meter.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="col-2">
                        <label className="form-label">Número de contadores</label>
                        <input type="number" className='form-control' name="ud" value={meterPrice.ud} onChange={handleMeter}/>
                    </div>
                    <div className="col-2">
                        <label className='form-label'>Importe:</label>
                        <input className='form-control' type="number" name="importe" value={meterPrice.importe} onChange={handleMeter}/>
                    </div>
                    <div className="col-2">
                        <p>Importe: </p>
                        <h4>{meterPrice.total} €</h4>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Meter